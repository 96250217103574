<nb-chat>
  <nb-chat-message *ngFor="let msg of messages"
                   [type]="msg.type"
                   [message]="msg.text"
                   [reply]="msg.reply"
                   [sender]="msg.user.name"
                   [date]="msg.date"
                   [avatar]="msg.user.avatar">
</nb-chat-message>

<nb-chat-form (send)="sendMessage($event)" [dropFiles]="true">
</nb-chat-form>
</nb-chat>
