import { Component } from '@angular/core';

@Component({
  selector: 'ngx-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent {
  messages: any[] =  [
        {
          text: 'Small!',
          date: new Date(),
          reply: true,
          user: {
            name: 'Bot',
            avatar: 'https://i.gifer.com/no.gif',
          },
        },
      ]

  sendMessage(event: any) {
    this.messages.push({
      text: event.message,
      date: new Date(),
      reply: true,
      user: {
        name: 'Yassine',
        avatar: 'https://i.gifer.com/no.gif',
      },
    },)
  }
}
