import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from "@nebular/theme";

import { UserData } from "../../../@core/data/users";
import { LayoutService } from "../../../@core/utils";
import { map, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

// ****** Added
import { inject } from "@angular/core";
import { Subscription } from "rxjs";
import { Auth, User, user, getAuth, signOut } from "@angular/fire/auth";
import { filter /*, map*/ } from "rxjs/operators";
//
import { NbGlobalPhysicalPosition, NbToastrService } from "@nebular/theme";
import { Router } from "@angular/router";

import { Auth99Service } from "../../../auth99.service";
import { NbSearchService } from "@nebular/theme";
import { NotificationsService } from "../../../services/notifications.service";
import { MessagingService } from "../../../services/messaging.service";
import { UserAuth } from "../../../models";
import { WilayaViewService } from "../../../services/wilaya-view.service";
import { Notification } from "../../../interfaces/interface";

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  // ****** Added
  private auth: Auth = inject(Auth);
  user$ = user(this.auth);
  userSubscription: Subscription;

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  name: string = "*";
  fullName: string = "*";
  role: string = "*";

  userAuth: UserAuth;

  date = new Date();

  userMenu = [
    { tag: "profile", title: "Profile", icon: "person-outline" },
    { tag: "settings", title: "paramètres", icon: "settings-2-outline" },
    { tag: "about", title: "à propos", icon: "info-outline" },
    { tag: "logout", title: "Log out", icon: "log-out-outline" },
  ];

  selectedWilaya: string;
  wilayas = [
    { name: "All", code: "" },
    { name: "Alger", code: "16" },
    { name: "Jijel", code: "18" },
    { name: "Tebessa", code: "12" },
  ];

  notifications: Notification[] = [];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private toastrService: NbToastrService,
    private aAuth99Service: Auth99Service,
    private searchService: NbSearchService,
    private wilayaViewService: WilayaViewService,
    private notificationService: NotificationsService,
    private router: Router
  ) {
    this.userAuth = this.aAuth99Service.getCurrentUserAuth();

    this.searchService.onSearchSubmit().subscribe((data: any) => {
      let searchTerm = data.term || "";
      searchTerm = searchTerm.toUpperCase();
      if (searchTerm.startsWith("W")) {
        this.router.navigate(["pages", "tarek-request2-edit", searchTerm]);
      } else if (searchTerm.startsWith("C")) {
        this.router.navigate(["pages", "tarek-request3-edit", searchTerm]);
      } else if (searchTerm.startsWith("P")) {
        this.router.navigate(["pages", "tarek-request3-edit", searchTerm]);
      } else {
        this.router.navigate(["pages", "tarek-search", searchTerm]);
      }
    });
  }

  menuServiceSubscription: Subscription;
  currentAuthUserSubscription: Subscription;
  ngOnInit() {
    this.currentAuthUserSubscription = this.aAuth99Service
      .getCurrentUserAuth$()
      .subscribe((aAuthUser) => {
        this.name = aAuthUser?.email || "**--";
        this.fullName = aAuthUser?.fullName || "**--";
        this.role = aAuthUser?.role || "**--";
      });

    this.userService
      .getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => (this.user = users.nick));

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );

    this.menuServiceSubscription = this.menuService
      .onItemClick()
      .pipe(filter(({ tag }) => tag == "user-context-menu"))
      .subscribe((item) => {
        const itemVal: any = item.item as any;
        if (itemVal.tag == "profile") {
          this.router.navigate(["pages", "tarek-profile"]);
        }
        if (itemVal.tag == "logout") {
          this.aAuth99Service.logout();
        }
        if (itemVal.tag == "about") {
          this.router.navigate(["pages", "info"]);
        }
        if (itemVal.tag == "settings") {
          this.router.navigate(["pages", "settings"]);
        }
      });

    setInterval(() => {
      this.currentTime();
    }, 1000);

    this.permissionWilayaHandler();

    this.notificationService
      .getNotifications()
      .pipe(takeUntil(this.destroy$))
      .subscribe((notifications) => {
        this.notifications = notifications;
      });
  }

  permissionWilayaHandler() {
    if (this.userAuth.role === "wilaya-desk") {
      localStorage.removeItem("wilaya");
    } else {
      const selectedWilayaOnInit = this.wilayaViewService.getSavedWilaya();

      this.selectedWilaya = selectedWilayaOnInit || "All";
      this.wilayaViewService.setWilaya(this.selectedWilaya);
    }
  }

  ngOnDestroy() {
    this.currentAuthUserSubscription.unsubscribe();
    this.menuServiceSubscription.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  showProfile() {
    this.router.navigate(["pages", "tarek-profile"]);
  }

  showInfo() {
    this.router.navigate(["pages", "info"]);
  }

  searchPage() {
    this.router.navigate(["pages", "search"]);
  }

  logout() {
    this.aAuth99Service.logout();
  }

  time: string;
  currentTime() {
    this.time = new Date().toLocaleTimeString();
  }

  isFullScreen: boolean = false;
  fullScreen() {
    if (document.fullscreenElement) {
      document.exitFullscreen();
      this.isFullScreen = false;
    } else {
      document.documentElement.requestFullscreen();
      this.isFullScreen = true;
    }
  }

  changeWilayaView(selectedWilaya: string) {
    this.wilayaViewService.setWilaya(selectedWilaya);
    window.location.reload();
  }

  navigateTo(url: string) {
    this.router.navigate([url]);
  }
}
