import { Component, Input } from "@angular/core";

@Component({
  selector: "ngx-update-dialog",
  styleUrls: ["./update-dialog.component.scss"],
  template: `
    <nb-card>
      <nb-card-header>{{ title }}</nb-card-header>
      <nb-card-body>{{ message }}</nb-card-body>
      <nb-card-footer>
        <button nbButton status="primary" (click)="reloadPage()">Actualiser la page</button>
      </nb-card-footer>
    </nb-card>
  `,
})
export class UpdateDialogComponent {
  @Input() title: string = "";
  @Input() message: string = "";

  reloadPage(): void {
    window.location.reload();
  }
}
