import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Notification } from '../interfaces/interface';
import { collection, Firestore, onSnapshot, query } from 'firebase/firestore';
import { Request3 } from '../models';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private notifications: BehaviorSubject<Notification[]> = new BehaviorSubject<Notification[]>([]);

  constructor(
  ) {}

  addNotification(notification: Notification) {
    const currentNotifications = this.notifications.value;
    this.notifications.next([...currentNotifications, notification]);
  }

  getNotifications(): Observable<Notification[]> {
    return this.notifications.asObservable();
  }

  clearNotifications() {
    this.notifications.next([]);
  }


}
