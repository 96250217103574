import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { collection, doc, getDocs, onSnapshot, orderBy, query } from 'firebase/firestore';
import { from, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NotificationsService } from './notifications.service';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  constructor(
    private fireStore: Firestore,
  ) {
    // this.listenForChanges()
  }

  getLatestVersion() {
    const queryArgs = [];
    const adminVersionCollection = collection(this.fireStore, "admin_version");

    return from(getDocs(query(adminVersionCollection, ...queryArgs))).pipe(
      map((querySnapshopt) => {
        if (!querySnapshopt) return console.error('no admin version found');

        const doc = querySnapshopt.docs[0];
        const data = doc.data() as AdminVersion
        return data.version
      }),
      catchError((err) => {
        console.log(err)
        return throwError(() => new Error('Failed to fetch latest version'));
      })
    )
  }

  listenForChanges() {
    const queryArgs = [];
    const adminVersionCollection = collection(this.fireStore, "admin_version");
    const sub = onSnapshot(
      query(adminVersionCollection, ...queryArgs),
      (snapShot) => {
        snapShot.docChanges().forEach(change => {
          if (change.type === 'modified') {
            console.log('Latest version is', change.doc.data())
          }
        })
      }
    )
  }
}


interface AdminVersion {
  createdAt: any
  version: string
}
